import React, { createContext, useState, useEffect, useContext } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../Firebaseconfig"; // Ensure correct path to Firebase configuration

// Create the context
const TestimoniesContext = createContext();

// Create a provider component
export const TestimoniesProvider = ({ children }) => {
  const [testimonies, setTestimonies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch testimonies from Firestore
  const fetchTestimonies = async () => {
    setLoading(true);
    try {
      const testimoniesCollection = collection(db, "testimonies");
      const testimonySnapshot = await getDocs(testimoniesCollection);
      const testimonyList = testimonySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTestimonies(testimonyList); // Store fetched testimonies in state
      setLoading(false);
    } catch (err) {
      console.error("Error fetching testimonies: ", err);
      setError("Failed to fetch testimonies");
      setLoading(false);
    }
  };

  // Fetch testimonies when the component mounts
  useEffect(() => {
    fetchTestimonies();
  }, []);

  return (
    <TestimoniesContext.Provider value={{ testimonies, loading, error }}>
      {children}
    </TestimoniesContext.Provider>
  );
};

// Create a custom hook to use the testimonies context
export const useTestimonies = () => {
  return useContext(TestimoniesContext);
};
