import React from 'react';
import PastorImage from '../Images/pastor.png'; // Replace with the correct path to your image

const PastorComponent = () => {
  return (
    <section className="bg-white py-12 px-4">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        
        {/* Pastor's Image */}
        <div className="w-full flex justify-center md:justify-start">
          <img
            src={PastorImage}
            alt="Pastor Kwame K Kingsley"
            className="w-96 h-auto object-cover rounded-lg shadow-md"
          />
        </div>

        {/* Pastor's Bio */}
        <div className="text-gray-800">
          <h1 className="text-4xl font-semibold text-center md:text-left mb-4 text-purple-700">
            Meet Our Pastor
          </h1>
          <p className="text-lg leading-relaxed">
            <strong>Pastor Kwame K Kingsley</strong>, a devoted servant of God, focuses on prayer, healing, and deliverance. Born in Ghana's Ashanti region, his commitment to the church began early. Pastor Kwame's leadership journey started at True Disciples Pentecost Church, where he led the youth ministry.
          </p>
          <p className="mt-4 text-lg leading-relaxed">
            In Italy, he contributed to the Holy Spirit Assemblies of God church, notably as a bass guitarist. After 7 years, he initiated a prayer meeting in his home, which quickly grew into a significant gathering in Modena, Italy.
          </p>
          <p className="mt-4 text-lg leading-relaxed">
            In 2013, Pastor Kwame established Mizpah International Ministry, with its roots in the USA, focusing on prayer, deliverance, and spiritual growth.
          </p>
        </div>
      </div>
    </section>
  );
};

export default PastorComponent;
