import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPen } from 'react-icons/fa'; // Importing a pen icon from react-icons
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../Firebaseconfig'; // Import your Firebase auth object

function AddArticleButton() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleAddArticleClick = () => {
    if (isAuthenticated) {
      navigate('/add-article');
    } else {
      alert('You have to Sign In before you can upload your articles.');
      navigate('/signin'); // Redirect to sign-in page if not authenticated
    }
  };

  return (
    <div className="fixed bottom-5 left-5 flex items-center space-x-2" style={{ zIndex: 1000 }}>
      <button
        className="bg-blue-600 text-white p-4 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
        aria-label="Add Article"
        onClick={handleAddArticleClick}
      >
        <FaPen size={20} />
      </button>
    </div>
  );
}

export default AddArticleButton;
