import React from 'react';
import { useArticles } from '../Context/ArticlesContext'; 
import { useNavigate } from 'react-router-dom';

function ArticlesCard() {
  const { articles, loading } = useArticles();
  const navigate = useNavigate();

  const handleReadMore = (articleId) => {
    navigate(`/articles/${articleId}`); 
  };

  if (loading) {
    return <div>Loading articles...</div>;
  }

  // Sort articles by timestamp in descending order (latest first)
  const sortedArticles = articles.sort((a, b) => {
    return (b.timestamp?.seconds || 0) - (a.timestamp?.seconds || 0);
  });

  return (
    <div className="container mx-auto py-8 px-4 lg:px-20">
      {/* Adjust the grid to have 4 cards per row on larger screens */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {sortedArticles.map((article) => {
          // Format the timestamp to a readable date (only date, no time)
          const formattedDate = article.timestamp?.seconds
            ? new Date(article.timestamp.seconds * 1000).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            : 'Invalid Date';

          return (
            <div
              className="cursor-pointer flex flex-col"
              key={article.id}
              onClick={() => handleReadMore(article.id)} // Navigate when clicking the entire card
            >
              <div className="bg-white shadow-lg overflow-hidden flex-grow">
                {/* If article has an image, display it; otherwise show a placeholder */}
                <img
                  src={article.imageUrl || 'https://via.placeholder.com/300x200'}
                  alt={article.title}
                  className="w-full h-48 object-cover" // Ensure image fits inside its container
                />
                <div className="p-4">
                  {/* Responsive title size */}
                  <h5 className="text-xl md:text-1xl lg:text-lg font-bold mb-2">
                    {article.title}
                  </h5>
                  <p className="text-sm text-gray-500 mb-1">
                    Written by: {article.user?.name || 'Anonymous'}
                  </p>
                  <p className="text-xs text-gray-500 mb-2">{formattedDate}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ArticlesCard;
