// firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"; // Import for Firebase Storage

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCh-dSjRIwKmv9_6_dQ_qJxsRDG8r9TauU",
  authDomain: "mizpahinternationalchurch.firebaseapp.com",
  projectId: "mizpahinternationalchurch",
  storageBucket: "mizpahinternationalchurch.appspot.com",
  messagingSenderId: "167177222865",
  appId: "1:167177222865:web:c8a74df917af5bd46b23f0",
  measurementId: "G-RX6FW06D63"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app); // Initialize Firebase Storage

export { auth, db, storage };
