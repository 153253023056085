import React,{useEffect} from 'react';
import Footer from '../Footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import AddArticleButton from './AddArticleButton';
import VideoBackground from '../Images/Article.mp4'; 
import ArticlesCard from './ArticlesCard';

function Articles() {

  useEffect(() => {
    window.scroll(0, 0)
}, [])

  return (
    <section id="articles" className="relative bg-gray-100">

      <div className="relative w-full">
        <video
          src={VideoBackground}
          autoPlay
          loop
          muted
          playsInline
          className="absolute inset-0 w-full object-cover h-[500px]"
        />

        <div className="absolute inset-0 bg-black bg-opacity-50"></div>


        <div className="relative z-10 flex flex-col items-center justify-center h-[500px]  text-center text-white px-4">
          <h1 className="text-4xl lg:text-6xl font-bold mb-3">Articles of Faith</h1>
          <p className="text-xl">Inspiration and spiritual growth from Mizpah International Ministry</p>
        </div>
      </div>

{/* Introduction Section */}
<div className="container mx-auto py-8 px-4 lg:px-20 text-justify text-gray-700">
  <p className="text-lg lg:text-xl leading-relaxed">
    At Mizpah, we believe in the transformative power of the Word for spiritual growth. The Bible reminds us in 
    <strong> 2 Timothy 3:16</strong> that "All Scripture is God-breathed and is useful for teaching, rebuking, correcting, and training in righteousness."
    Our articles are designed to offer spiritual insight and growth, providing guidance through scriptures like 
    <strong> Psalm 119:105</strong>, which says, "Your word is a lamp to my feet and a light to my path."
    <br /><br />
    As you read through these articles, let them inspire, uplift, and strengthen your walk of faith. Additionally, we invite you to contribute by sharing your own articles! Click on the pencil icon in the lower left corner of the page to add your own piece, helping others grow in their spiritual journey as well.
  </p>
</div>

      <ArticlesCard/>
      <AddArticleButton />

      {/* Footer */}
      <Footer />
    </section>
  );
}

export default Articles;
