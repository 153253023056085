import React from "react";
import { Carousel } from 'react-bootstrap'; // Bootstrap Carousel
import Button from '@mui/material/Button'; // Material UI Button for more style options
import 'bootstrap/dist/css/bootstrap.min.css';
import './Carousel.css'; // Custom CSS file for additional styling
import Donate from "../Images/Donate.mp4";
import Prayer from "../Images/Prayer.mp4"
import Article from "../Images/Article.mp4"
import Testimony from "../Images/Testimony.mp4"
import Welcome from "../Images/Welcome.jpg"

const carouselData = [
  {
    mediaType: 'image', // Indicating this is an image
    mediaSrc: Welcome,
    title: "Welcome to Mizpah International Ministry",
    subtitle: "Join us in worship and experience the love of Christ.",
    buttonText1: "Our Beliefs",
    buttonLink1: "/about",
    buttonText2: "Watch Us on YouTube",
    buttonLink2: "https://www.youtube.com/@mizpahinternationalministr783"
  },
  {
    mediaType: 'video', 
    mediaSrc: Prayer, 
    title: "Join Us For Prayers and Worship",
    subtitle: "Check below for our prayer schedule and join us for an amazing service",
    buttonText1: "Request For Prayer",
    buttonLink1: "/request",
    buttonText2: "Join Us to Pray",
    buttonLink2: "https://us02web.zoom.us/j/3022979645?pwd=UFIvYTloM09uZ1IyeFZ5TG9RVng0UT09"
  },
  {
    mediaType: 'video', 
    mediaSrc: Article, 
    title: "Explore and Share Your Own Articles",
    subtitle: "Read the latest articles for spiritual insights or contribute by writing and publishing your own, helping others grow in their faith.",
    buttonText1: "Explore/Share Articles",
    buttonLink1: "/articles",
    buttonText2: "Join Us On Instagram",
    buttonLink2: "https://www.instagram.com/mizpah_family"
  },
  {
    mediaType: 'video',
    mediaSrc: Donate,
    title: "Donate to Support Our Ministry",
    subtitle: "Your generous donations help us continue our mission to spread the Gospel and serve our community.",
    buttonText1: "Donate Here",
    buttonLink1: "/give",
    buttonText2: "Our Facebook Page",
    buttonLink2: "https://www.facebook.com/mizpahministryworldoutreach?mibextid=b06tZ0"
  },
  {
    mediaType: 'video',
    mediaSrc: Testimony,
    title: "Share Your Testimony",
    subtitle: "Share how God has worked in your life and be a light to those seeking faith and hope and uplift their spirit.",
    buttonText1: "Share Testimonies",
    buttonLink1: "/testimonies",
    buttonText2: "Our Tiktok Page",
    buttonLink2: "https://www.tiktok.com/@mizpah_family1?_t=8fdBkpD3mrN&_r=1"
  },
];

const LandingPage = () => {
  return (
    <div className="landing-page">
      <Carousel fade controls={true} indicators={true} interval={8000} pause={false} keyboard='true' >
        {carouselData.map((item, index) => (
          <Carousel.Item key={index}>
            {/* Conditionally render image or video */}
            {item.mediaType === 'image' ? (
              <img
                className="d-block w-100 full-carousel-item"
                src={item.mediaSrc}
                alt='Mizpah International Ministry'
              />
            ) : (
              <video
                className="d-block w-100 full-carousel-item"
                src={item.mediaSrc}
                autoPlay
                muted
                loop
                playsInline
              />
            )}

            {/* Full-screen tinted caption */}
            <Carousel.Caption className="carousel-caption-full bg-black bg-opacity-50 d-flex flex-column justify-content-center align-items-center">
              <h1 className="text-3xl font-bold text-white mb-2">{item.title}</h1>
              <p className="text-lg text-white mb-3">{item.subtitle}</p>

              {/* Two buttons with responsive layout */}
              <div className="button-group d-flex flex-column flex-md-row justify-content-center">
                <Button
                  variant="contained"
                  href={item.buttonLink1}
                  sx={{
                    backgroundColor: "#4B0082",
                    color: "white",
                    '&:hover': { backgroundColor: '#5e00a4' },
                    margin: '5px',
                    width: '100%',
                    flex: 1
                  }}
                >
                  {item.buttonText1}
                </Button>
                <Button
                  variant="contained"
                  href={item.buttonLink2}
                  sx={{
                    backgroundColor: "#008000",
                    color: "white",
                    '&:hover': { backgroundColor: '#00a600' },
                    margin: '5px',
                    width: '100%',
                    flex: 1
                  }}
                >
                  {item.buttonText2}
                </Button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default LandingPage;
