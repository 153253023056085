import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { motion } from "framer-motion";
import { auth, db } from "../../Firebaseconfig"; // Ensure the correct path
import { doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 
import Confetti from "react-confetti";

const AddTestimony = () => {
  const [message, setMessage] = useState("");
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false); 
  const [showConfetti, setShowConfetti] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          setUser(userDoc.data());
        }
      } else {
        navigate("/signup"); 
      }
    };

    fetchUserData();
  }, [navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!user) return;

    setLoading(true); 

    const testimony = {
      id: `unique-id-${Date.now()}`, 
      user: {
        name: user.firstName, 
        email: user.email,
      },
      message,
      date: Timestamp.now(), 
      location: user.country  || "Not provided", 
    };

    try {
      await setDoc(doc(db, "testimonies", testimony.id), testimony);
      toast.success("Testimony shared successfully!");
      setShowConfetti(true); // Show confetti
      setTimeout(() => navigate("/testimonies"), 3000); // Redirect after 3 seconds
    } catch (error) {
      console.error("Error adding testimony: ", error);
      toast.error("Failed to add testimony. Please try again.");
    } finally {
      setLoading(false); 
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <ToastContainer />

      {showConfetti && <Confetti />}

      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography
          component="h1"
          variant="h5"
          className="text-center pt-3"
          style={{ fontWeight: 700 }}
        >
          Add Your Testimony
        </Typography>

        {/* Motivational Scriptures */}
        <Typography
          variant="body1"
          align="center"
          style={{ marginTop: 20, fontStyle: "italic" }}
        >
          "They triumphed over him by the blood of the Lamb and by the word of their testimony."
          <br />— Revelation 12:11
        </Typography>
        <Typography
          variant="body1"
          align="center"
          style={{ marginTop: 10, fontStyle: "italic" }}
        >
          "Let the redeemed of the Lord tell their story."
          <br />— Psalm 107:2
        </Typography>
        <Typography
          variant="body1"
          align="center"
          style={{ marginTop: 10, fontStyle: "italic" }}
        >
          "Come and hear, all you who fear God; let me tell you what He has done for me."
          <br />— Psalm 66:16
        </Typography>

        <form noValidate onSubmit={handleSubmit}>
          {/* Larger input field */}
          <TextField
            variant="outlined"
            required
            fullWidth
            multiline
            rows={8}  
            label="Add Your Testimony"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            style={{ marginTop: 20 }}
            disabled={loading} 
            placeholder="Add your Testimony Here, as long as you want it to inspire other of the great works the Lord has done for you"
          />

          <Typography
            variant="caption"
            display="block"
            gutterBottom
            style={{ marginTop: 10 }}
          >
            NB: Your name will be shared when displaying your testimony. Amen.
          </Typography>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: 20 }}
            disabled={loading} 
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Submit Testimony"}
          </Button>
        </form>
      </motion.div>
    </Container>
  );
};

export default AddTestimony;
