import React, { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Divider, Avatar } from '@mui/material';
import { FaHeart, FaSignInAlt, FaSignOutAlt, FaBars, FaHome, FaEnvelope, FaInfoCircle, FaClock, FaTimes, FaMicrophone } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../../Firebaseconfig';
import Logo from '../Images/logo.png'; 

const NavBar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLoggedIn(true);
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUser(userDoc.data());
        }
      } else {
        setIsLoggedIn(false);
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleBothActions = () => {
    handleSignOut();
    toggleDrawer();
  };

  return (
    <div className="relative">
      {/* Desktop Navigation */}
      <nav className="bg-[#4B0082] hidden md:flex justify-between items-center">
        <div className="flex space-x-8 text-lg items-center flex-1 pl-[20%]">
          <Link to="/" className="text-white no-underline hover:underline">Home</Link>
          <Link to="/about" className="text-white no-underline hover:underline">Beliefs</Link>
          <Link to="/contact" className="text-white no-underline hover:underline">Events</Link>
          <img src={Logo} alt="Mizpah International Ministry" className="h-auto w-52 mx-4" />
          <Link to="/sermons" className="text-white no-underline hover:underline">Sermons</Link>
          <Link to="/articles" className="text-white no-underline hover:underline">Articles</Link>
          <Link to="/testimonies" className="text-white no-underline hover:underline">Testimonies</Link>
          <Link to="/give" className="text-white no-underline hover:underline">Give</Link>
        </div>
        <div className="flex items-center justify-end mr-20">
          {isLoggedIn && user ? (
            <>
              <div className="ml-4">
                <Link to="#" onClick={handleSignOut} className="text-white no-underline hover:underline text-lg">Log Out</Link>
              </div>
            </>
          ) : (
            <>
              <Link to="/signin" className="text-white no-underline hover:underline text-lg">Log In</Link>
            </>
          )}
        </div>
      </nav>

      {/* Mobile Navigation */}
      <nav className="bg-[#4B0082] flex md:hidden justify-between items-center p-2">
        <div className="flex items-center ">
          <img src={Logo} alt="Mizpah International Ministry" className="h-auto w-32" /> {/* Reduced logo size */}
        </div>
        <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer} style={{marginRight: 5}}>
          <FaBars className="text-white text-xl" /> {/* Reduced icon size */}
        </IconButton>
      </nav>

      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <div className="w-64 bg-[#4B0082] h-full text-white pt-4"> {/* Reduced drawer width */}
          <div className="flex items-center justify-between p-2">
            {isLoggedIn && user ? (
              <>
                <div className="flex items-center">
                  <Avatar alt={user.firstName} src={user.avatarUrl || "https://via.placeholder.com/50"} />
                  <div className="ml-2">
                    <h6 className="text-md font-semibold">{user.firstName}</h6> {/* Reduced text size */}
                    <p className="text-xs">You are welcome </p> {/* Reduced text size */}
                  </div>
                </div>
              </>
            ) : (
              <p className="text-md font-semibold">Guest</p>
            )}
            <IconButton edge="end" color="inherit" aria-label="close" onClick={toggleDrawer} style={{marginRight: 5}}>
              <FaTimes className="text-white text-xl" /> {/* Reduced icon size */}
            </IconButton>
          </div>
          <Divider className="bg-white h-1" />
          <List>
            <Link onClick={toggleDrawer} to="/" className={`no-underline ${location.pathname === '/' ? 'bg-purple-700' : ''}`}>
              <ListItem button>
                <ListItemIcon><FaHome className="text-white" /></ListItemIcon>
                <ListItemText primary="Home" className="text-white text-sm" /> {/* Reduced text size */}
              </ListItem>
            </Link>
            <Link to="/contact" onClick={toggleDrawer} className={`no-underline ${location.pathname === '/contact' ? 'bg-purple-700' : ''}`}>
              <ListItem button>
                <ListItemIcon><FaEnvelope className="text-white" /></ListItemIcon>
                <ListItemText primary="Events" className="text-white text-sm" /> {/* Reduced text size */}
              </ListItem>
            </Link>
            <Link to="/about" onClick={toggleDrawer} className={`no-underline ${location.pathname === '/about' ? 'bg-purple-700' : ''}`}>
              <ListItem button>
                <ListItemIcon><FaInfoCircle className="text-white" /></ListItemIcon>
                <ListItemText primary="Beliefs" className="text-white text-sm" /> {/* Reduced text size */}
              </ListItem>
            </Link>
            <Link to="/testimonies" onClick={toggleDrawer} className={`no-underline ${location.pathname === '/testimonies' ? 'bg-purple-700' : ''}`}>
              <ListItem button>
                <ListItemIcon><FaMicrophone className="text-white" /></ListItemIcon>
                <ListItemText primary="Testimonies" className="text-white text-sm" /> {/* Reduced text size */}
              </ListItem>
            </Link>
            <Link to="/articles" onClick={toggleDrawer} className={`no-underline ${location.pathname === '/articles' ? 'bg-purple-700' : ''}`}>
              <ListItem button>
                <ListItemIcon><FaClock className="text-white" /></ListItemIcon>
                <ListItemText primary="Articles" className="text-white text-sm" /> {/* Reduced text size */}
              </ListItem>
            </Link>
            <Link to="/give" onClick={toggleDrawer} className={`no-underline ${location.pathname === '/give' ? 'bg-purple-700' : ''}`}>
              <ListItem button>
                <ListItemIcon><FaHeart className="text-white" /></ListItemIcon>
                <ListItemText primary="Give" className="text-white text-sm" /> {/* Reduced text size */}
              </ListItem>
            </Link>
            {!isLoggedIn && (
              <>
                <Link to="/signin" onClick={toggleDrawer} className={`no-underline ${location.pathname === '/login' ? 'bg-purple-700' : ''}`}>
                  <ListItem button>
                    <ListItemIcon><FaSignInAlt className="text-white" /></ListItemIcon>
                    <ListItemText primary="Log In" className="text-white text-sm" /> {/* Reduced text size */}
                  </ListItem>
                </Link>
              </>
            )}
            {isLoggedIn && (
              <Link to="#" onClick={handleBothActions}  className={`no-underline ${location.pathname === '/logout' ? 'bg-purple-700' : ''}`}>
                <ListItem button>
                  <ListItemIcon><FaSignOutAlt className="text-white" /></ListItemIcon>
                  <ListItemText primary="Log Out" className="text-white text-sm" /> {/* Reduced text size */}
                </ListItem>
              </Link>
            )}
          </List>
        </div>
      </Drawer>
    </div>
  );
};

export default NavBar;
