import React from 'react'
import { useEffect } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';

import Daysofrediscovery from '../Images/daysofrediscovery.webp'
import Prayerandfasting from "../Images/prayerandfasting.jpeg"
import Pray from "../Images/pray.jpeg"


import { Link } from 'react-router-dom';

const messageInfo = [
  {
    key:"1",
    type: "message",
    time: "Every Meeting Day",
    header: "From Pastor Kwame",
    title: "MIZPAH FAMILY(WINNERS HOUR)",
    text: `Hey there! Just wanted to reach out and let you know that MIZPAH FAMILY (WINNERS HOUR) has something special planned for you. We're hosting a Zoom meeting called "MIZPAH FAMILY (WINNERS HOUR)'s Personal Meeting Room." It's gonna be an amazing time of prayer and fellowship. We believe that prayer has the power to transform lives, so we really encourage you to join us. And don't forget to invite others too! Together, we can make a difference through the power of prayer. Remember what it says in Luke 18:1, "Men always ought to pray. Don't forget to subscribe to our Newsletter to receive updates`,
    buttontext: "Join Zoom",
    messagelink: "https://us02web.zoom.us/j/3022979645?pwd=UFIvYTloM09uZ1IyeFZ5TG9RVng0UT09",
  },
  {
    key:"1",
    type: "message",
    time: "3rd/Dec - 23rd/Dec",
    header: "From Pastor Kwame",
    title: "21 Days Fasting & Prayer?",
    text: `Exciting news! Mizpah International Ministry is hosting a 21-day fasting and prayer event from December 3rd to December 23rd, 2023. The theme is "I Will Speak". We'll be reading and reflecting on Acts 4:18-20. All the meetings will be on Zoom, following our usual protocols. Save the dates and get ready for an incredible journey of fasting and prayer. More details and updates will be on mizpahfamily.com. Let's come together, seek God, and experience breakthroughs. Can't wait to see you there! If you have any questions, feel free to reach out. Blessings!`,
    buttontext: "Inquiries",
    messagelink: "https://wa.me/15133282864",
  },
  { 
    key:'1',
    type: "article",
    time: "13/Nov - 13/Dec",
    header: "New Article",
    title: "30 Days of Rediscovery",
    text: "Hello Children Of God, join us at Mizpah International for '30 Days of Rediscovery' starting from November 13th to December 13th, 2023. Experience the power of prayer and connect with God through our exclusive articles on our website and detailed video-audio explanations on our social media platforms. See you there! 🙏🌟",
    buttontext: "Read Article",
    messagelink: "/articles",
  },

]

const eventsInfo = [
  {
    title: "MIZPAH FAMILY (WINNERS HOUR)",
    src: Pray,
    link:"https://wa.me/+15133282864",
    text:"MIZPAH FAMILY (WINNERS HOUR) is inviting you to a scheduled Zoom meeting. Topic: MIZPAH FAMILY (WINNERS HOUR)'s Personal Meeting Room. Join Zoom Meeting. Meeting ID: 302 297 9645. Passcode: pray BRETHREN IT'S PRAYER TIME, JOIN AND YOUR LIFE WILL NOT BE SAME, INVITE OTHERS TO JOIN US. MEN ALWAYS OUGHT TO PRAY. LUKE 18:1"    
  },
  {
    title: "I Will Speak?",
    src: Prayerandfasting,
    link:"https://wa.me/15133282864",
    text:"Mizpah International Ministry invites you to a 21 day prayer and fasting event, starting from 3rd December to 23rd December. Join us on `I Will Speak` (Act 4:18-20) with Pastor Kwame. Join us Monday to Friday, 5:15-6 am, Monday to Saturday, 12-12:30 pm, Monday to Thursday, 7-8 pm, and Friday to Sunday, 5-6 pm. Connect via Zoom!"
  },
  {
    title: "30 Days Of Rediscovery",
    src: Daysofrediscovery,
    link:"https://wa.me/+233542353326",
    text:"    We're excited to invite you to our upcoming event, '30 Days of Rediscovery.' 🎉📚✨ Starting from November 13th to December 13th, 2023, join us for a transformative journey where we'll explore the powerful connection with God through prayer. 🌟🙏 During these 30 days, we'll be providing detailed video and audio explanations on our social media platforms, guiding you on this incredible path of rediscovery. 🎥🎧 And remember, the articles will be exclusively available on our website. 📝💻 Don't miss out on this opportunity to deepen your connection with God through prayer. Mark your calendars and get ready for an amazing experience! See you there! 🤗✨"    
  },
]

function Contact() {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  return (
    <div className='container'>
      <Tabs
        defaultActiveKey="message" id="fill-tab-example" className="mb-3 container" fill
      >
        <Tab eventKey="message" title=" Messages">
          <h2 className="font-pFonts text-center pb-3">Keep Updated With Messages From Mizpah Family</h2>
          {
            messageInfo.map(({ key, time, type, header, title, text, buttontext, messagelink }) => {
              if (type === "message") {
                return (
                  <Accordion className='m-3'>
                    <Accordion.Item eventKey={key}>
                      <Accordion.Header className='d-flex justify-content-between align-items-center'>
                        <div className='mr-[30%]'>
                          <Badge className='bg-danger font-titleFonts'>message</Badge>
                          {header}
                        </div>
                        {time}
                      </Accordion.Header>
                      <Accordion.Body>
                        <Card>
                          <Card.Body>
                            <Card.Title>{title}</Card.Title>
                            <Card.Text>
                              {text}
                            </Card.Text>
                            <Button variant="primary" href={messagelink}>{buttontext}</Button>
                          </Card.Body>
                        </Card>
                      </Accordion.Body>

                    </Accordion.Item>
                  </Accordion>
                )
              }
              else if (type === "article") {
                return (
                  <Accordion className='m-3'>
                    <Accordion.Item eventKey={key}>
                      <Accordion.Header className='d-flex justify-content-between align-items-center'>
                        <div className='mr-[30%]'>
                          <Badge className='bg-danger font-titleFonts'>article</Badge>
                          {header}
                        </div>
                        {time}
                      </Accordion.Header>
                      <Accordion.Body>
                        <Card>
                          <Card.Body>
                            <Card.Title>{title}</Card.Title>
                            <Card.Text>
                              {text}
                            </Card.Text>
                            <Link to={messagelink} >
                              <Button variant="primary" >{buttontext}</Button>
                            </Link>
                          </Card.Body>
                        </Card>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )
              }
            })
          }
        </Tab>


        <Tab eventKey="Evnts" title=" Events">
          <div className="d-flex flex-wrap">      {
            eventsInfo.map(({ title, text, link, src }) => {
              return (
                <div className="col-md-4 col-12">
                  <Card className='m-4 '>
                    <Card.Img variant="top" src={src} alt='Mizpah International Ministry' />
                    <Card.Body>
                      <Card.Title className='font-pFonts'>{title}</Card.Title>
                      <Card.Text className='font-h2Fonts text-extrabold'>
                        {text}
                      </Card.Text>
                      <Button variant="primary" href={link}>Inquiries</Button>
                    </Card.Body>
                  </Card>
                </div>
              )
            })
          }
          </div>
        </Tab>
      </Tabs>
    </div>
  )
}

export default Contact
