import React from 'react'
import MeetingSchedule from './MeetingSchedule';
import PastorComponent from './PastorComponent';
import LandingPage from './LandingPage';
import './Carousel.css'

import Footer from '../Footer/Footer';



function Banner() {

    return (
        <section className="home no-padding" id="home">
        <LandingPage />
        <MeetingSchedule />
        <PastorComponent />
        <Footer />
      </section>
    )
}

export default Banner
