import React from 'react'
import '../FAQ/Faq.css'
import Accordion from 'react-bootstrap/Accordion';
import { useEffect } from 'react';
import Footer from '../Footer/Footer';

const faqInfo = [
    {
        eventkey: '0',
        faq: "What are the service times?",
        answer: "Our services are daily, timed to suit folks in the USA, Kenya, Ghana, UK, and Jamaica. Visit our home screen for more info   ",
    },
    {
        eventkey: '2',
        faq: "Do you have programs for children?",
        answer: "Absolutely! We have engaging and age-appropriate programs for children of all ages.",
    },
    {
        eventkey: '3',
        faq: "How can I get involved in volunteering?",
        answer: "We would love to have you join our volunteer team! Please reach out to our volunteer coordinator for more information.",
    },
    {
        eventkey: '4',
        faq: "Is there a dress code for attending services?",
        answer: "We encourage people to come as they are. There is no specific dress code for our services.",
    },
    {
        eventkey: '5',
        faq: " How can I donate to the church?",
        answer: "We accept donations through various methods, including online giving, in-person during services, and through mail. Visit our Give page for more info",
    },
    {
        eventkey: '6',
        faq: "Can I attend the church if I'm not a member?",
        answer: " Absolutely! Our church warmly welcomes both members and non-members to our services.",
    },
    {
        eventkey: '7',
        faq: " What safety measures are in place during services?",
        answer: "We prioritize the safety of our congregation and have implemented safety protocols during services.",
    },
    {
        eventkey: '8',
        faq: " Are there opportunities for spiritual growth and study?",
        answer: " Yes, we offer Bible study groups and other spiritual growth opportunities. Dive into our articles for inspiration and enlightenment.",
    },
]
const faqInfo2 = [
    {
        eventkey: '0',
        faq: "Can I schedule a meeting with the pastor?",
        answer: "Absolutely! Contact our office to schedule a meeting with the pastor.",
    },
    {
        eventkey: '1',
        faq: " How can I request a prayer or share a prayer request?",
        answer: "You can submit prayer requests through our website or contact our prayer team directly.",
    },
    {
        eventkey: '2',
        faq: "Do you offer counseling services?",
        answer: "Yes, we have counseling services available. Please contact our office to schedule an appointment.",
    },
    {
        eventkey: '3',
        faq: "How can I join the worship team or choir or any other team?",
        answer: "We welcome new members to our worship team and choir. Reach out to our office for more information.",
    },
    {
        eventkey: '4',
        faq: "Is there a dress code for attending services?",
        answer: "We encourage modesty and respect, but there is no strict dress code.",
    },
    {
        eventkey: '5',
        faq: " How can I stay updated on church events and announcements?",
        answer: "Subscribe to our newsletter or follow us on social media for the latest updates.",
    },
    {
        eventkey: '6',
        faq: "Are there opportunities for community service and outreach?",
        answer: "Absolutely! We always have opportunities for community service and outreach. Get involved!",
    },
    {
        eventkey: '7',
        faq: " What safety measures are in place during services?",
        answer: "We prioritize the safety of our congregation and have implemented safety protocols during services.",
    },
    {
        eventkey: '8',
        faq: " Are there opportunities for spiritual growth and study?",
        answer: " Yes, we offer Bible study groups and other spiritual growth opportunities. Dive into our articles for inspiration and enlightenment.",
    },
]
function Faq() {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (

        <div id='faq'>
            <div id='faq-header' className='header font-pFonts text-white text-center'>
                <h1 className='pt-[150px] font-h2Fonts text-6xl  pb-3'>FAQ</h1>
                <h3 className='pb-[150px]'>Guiding Light: Your Questions Answered on the Journey with Mizpah</h3>
            </div>
            <div className="row">
                <div className="col-md-4 sm-12">
                    {
                        faqInfo.map(({ eventkey, faq, answer }) => {
                            return (
                                <Accordion defaultActiveKey="0" className='p-2 w-[100%]'>
                                    <Accordion.Item eventKey={eventkey} className='mb-1'>
                                        <Accordion.Header>{faq}</Accordion.Header>
                                        <Accordion.Body>
                                            {answer}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            )
                        })
                    }
                </div>
                <div className="col-md-4 sm-12">
                    {
                        faqInfo2.map(({ eventkey, faq, answer }) => {
                            return (
                                <Accordion defaultActiveKey="0" className='p-2 w-[100%]'>
                                    <Accordion.Item eventKey={eventkey} className='mb-1'>
                                        <Accordion.Header>{faq}</Accordion.Header>
                                        <Accordion.Body>
                                            {answer}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            )
                        })
                    }
                </div>

                <div className="col-md-4 sm-12">

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Faq
