import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore"; // Ensure the correct path to your Firebase setup
import { db } from "../../Firebaseconfig"; // Import your Firebase config
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const TestimoniesDetails = () => {
  const { id } = useParams(); // Get the testimony ID from the URL
  const [testimony, setTestimony] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTestimony = async () => {
      const docRef = doc(db, "testimonies", id); // Fetch the testimony by ID
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setTestimony(docSnap.data());
      } else {
        console.log("No such document!");
      }
      setLoading(false);
    };
    fetchTestimony();
  }, [id]);

  if (loading) return <div>Loading...</div>;

  // Extract the first two names from the user's full name
  const getFirstTwoNames = (fullName) => {
    const names = fullName.split(" ");
    return names.length >= 2 ? `${names[0]} ${names[1]}` : fullName;
  };

  const styles = {
    container: {
      padding: '20px', // Default padding for all screens
      maxWidth: '800px',
      margin: '0 auto',
    },
    heading: {
      fontSize: '2rem', // Default font size
      fontWeight: 'bold',
      marginBottom: '20px',
    },
    paragraph: {
      fontSize: '1rem',
      lineHeight: '1.6',
      marginBottom: '10px',
      textAlign:'justify'
    },
    date: {
      marginTop: '20px',
      fontStyle: 'italic',
    },

  };

  return (
    <div style={styles.container}>
      {testimony ? (
        <>
          <h3 className="font-bold">{getFirstTwoNames(testimony.user.name)}'s Testimony</h3>
          <p style={styles.paragraph}><strong>Location:</strong> {testimony.location}</p>
          <p style={styles.paragraph}>{testimony.message}</p>
          <p style={styles.date}><strong>Date:</strong> {new Date(testimony.date.seconds * 1000).toLocaleDateString()}</p>
          <Link to={`/add-testimony`} style={{ textDecoration: 'none' }}>
          <Button 
            variant="contained" 
            color="primary" 
            fullWidth 
            sx={{ 
              padding: '12px',
              borderRadius: '8px',
              fontWeight: 'bold',
              backgroundColor: '#007bff',
              '&:hover': {
                backgroundColor: '#0056b3',
              }
            }}
          >
            Add Your Testimony
          </Button>
        </Link>
        </>
      ) : (
        <p>No testimony found.</p>
      )}
    </div>
  );
};

export default TestimoniesDetails;
