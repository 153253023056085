import React from 'react'
import '../PrayerRequest/PrayerRequest.css'
import Footer from '../Footer/Footer'
import { useEffect } from 'react';



function PrayerRequest() {

  useEffect(() => {
    window.scrollTo(0,0)
  },[])
const sendEmail = (event) => {
    event.preventDefault();
    window.location.href = `mailto:mizpahint.ministries77@gmail.com?subject=Contact Form Submission&body=
    Name: ${event.target.elements.name.value}, 
    Email: ${event.target.elements.email.value}, 
    Message:${event.target.elements.message.value}, 
    Tel:${event.target.elements.telephone.value}`;
  };
  return (
    <div id='request'>
        <div id='request-header' className='request-banner font-pFonts text-white text-center'>
            <h1 className='pt-[150px] font-h2Fonts text-6xl  pb-3'>PRAYER</h1>
            <h3 className='pb-[150px]'>We Unite In Prayer For Global And Personal Needs</h3>
        </div>
        <div className="info">
          <h1 className='text-center font-h2Fonts'>THE PRAYER MINISTRY</h1>
          <p className='p-3 text-justify font-pFonts'>At Mizpah International Ministry, we're privileged to pray for all life's moments, both challenging and joyous. We believe in the power of shared prayer, celebrating victories and providing support in times of need. We're here to uplift and inspire, guided by the words of Thessalonians 5:16-18, "Rejoice always, pray without ceasing, give thanks in all circumstances." Our mission is to embody these values, fostering a community of faith, hope, and love.</p>
        </div>
    <h1 className='text-center font-pFonts'>Send Us You Prayer Request</h1>
               <form class="contact-form d-flex justify-content-center container" onSubmit={sendEmail}>
          <div class="row">
            <div class="col-sm-4">
              <input class="form-control" type="text" name="name" placeholder="Input Name" required />
            </div>
            <div class="col-sm-4">
              <input class="form-control" type="email" name="email" placeholder="Input Email" required />
            </div>
            <div class="col-sm-4">
              <input class="form-control" type="tel" name="telephone" placeholder="Enter Your Telephone" required />
            </div>
            <div class="col-sm-12">
              <input class="form-control text-area" as="text-area" name="message" placeholder="How Can We Help You" required />
            </div>
            <div class="col-sm-12">
              <button className='' type="submit">Send Request</button>
            </div>
          </div>
        </form>
        
        <div className='quotes'>
          <h1 className='text-center'>Do not be anxious about anything, but in everything by prayer and pleading with thanksgiving let your requests be made known to God.</h1>
          <p className='text-center'>Philippians 4:6</p>
        </div>
        <Footer/>
    </div>
  )
}

export default PrayerRequest
