import React from 'react'
import '../Give/Give.css'
import Footer from '../Footer/Footer'
import Payment from '../Images/giving.jpeg'
import { useEffect } from 'react'

import image1 from '../Images/donations/1.jpg'
import image6 from '../Images/donations/6.jpg'
import image7 from '../Images/donations/7.jpg'
import image8 from '../Images/donations/8.jpg'
import image9 from '../Images/donations/9.jpg'
import image2 from '../Images/donations/2.jpeg'
import image3 from '../Images/donations/3.jpeg'
import image4 from '../Images/donations/4.jpeg'
import image5 from '../Images/donations/5.jpeg'


import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const donationimages = [
  {
    src:image1
  },
  {
    src:image2
  },
  {
    src:image3
  },
  {
    src:image4
  },
  {
    src:image5
  },
  {
    src:image6
  },
  {
    src:image7
  },
  {
    src:image8
  },
  {
    src:image9
  },
]


function Give() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  
  return (
    <div id='give'>
      <div id='give-header' className='give-banner font-pFonts text-white text-center'>
            <h1 className='pt-[150px] font-h2Fonts text-6xl  pb-3'>Giving</h1>
            <h3 className='pb-[150px]'>We Come Together In The Spirit Of Generosity, Addressing Needs Near And Far.</h3>
        </div>
        <div className="info">
          <h1 className='text-center font-h2Fonts uppercase'>Why Share Our Blessings</h1>
          <p className='p-3 text-justify font-pFonts'>In Mizpah International Ministry, we echo 2 Corinthians 9:7, "Each of you should give what you have decided in your heart to give, not reluctantly or under compulsion, for God loves a cheerful giver." <br /><br />
           God isn't a taker, He's the ultimate giver, John 3:16 reminds us, "For God so loved the world that He gave His one and only Son." So, we give our offerings, not because God needs them, but because we love Him. <br /><br />
            We say, "God, I want the world to know about Jesus. So, I'm giving to Mizpah International Ministry, confident that my gifts will help spread the Gospel." We pray that God, who "is able to bless you abundantly" (2 Corinthians 9:8), will capture our hearts today, reminding us of His love and care. Everything we have is from Him (James 1:17), and we join Him in His work with our time, energy, gifts, opportunities, and money. We invest in eternal things, sewing into God's work here in this ministry. <br /><br /> All for Jesus!</p>
        </div>
       
       
       <Carousel responsive={responsive}>
        {
          donationimages.map(({src}) => {
            return(
              <div>
                <img className='p-4 w-[5000px]' src={src} alt="" />
              </div>
            )
          })
        }
       </Carousel>
        
        <Footer/>
    </div>
  )
}

export default Give
