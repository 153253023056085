import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, Avatar, CircularProgress } from '@mui/material';
import { Editor, EditorState, RichUtils, convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { auth, db, storage } from '../../Firebaseconfig';
import { collection, addDoc, serverTimestamp, getDoc, doc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Confetti from 'react-confetti';

function AddArticlePage() {
  const [upload, setUpload] = useState(true);
  const [article, setArticle] = useState({
    title: '',
    bibleVerse: '',
    summary: '',
    prayer: '',
  });

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [loading, setLoading] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setArticle((prevArticle) => ({
      ...prevArticle,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
      setUpload(false);
    }
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const toggleInlineStyle = (style) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  const toggleBlockType = (blockType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    if (article.title.length > 30) {
      alert('Title must be 30 characters or less. Please correct it before publishing.');
      setLoading(false);
      return;
    }
  
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
  
    try {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
  
      let userFirstName = 'Anonymous';
      let userEmail = 'No email';
  
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        userFirstName = userData.firstName || 'Anonymous';
        userEmail = userData.email || 'No email';
      }

      const articleToSubmit = {
        ...article,
        body: rawContent,
        user: {
          name: userFirstName,
          email: userEmail,
        },
        timestamp: serverTimestamp(),
      };
  
      let imageUrl = '';
      if (image) {
        const imageRef = ref(storage, `articles/${Date.now()}_${image.name}`);
        const snapshot = await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(snapshot.ref);
      }
  
      if (imageUrl) {
        articleToSubmit.imageUrl = imageUrl;
      }
  
      await addDoc(collection(db, 'articles'), articleToSubmit);
  
      setArticle({
        title: '',
        bibleVerse: '',
        summary: '',
        prayer: '',
      });
      setEditorState(EditorState.createEmpty());
      setImage(null);
      setImagePreview('');
      setUpload(true);
      setLoading(false);
      setShowConfetti(true);
  
      setTimeout(() => setShowConfetti(false), 5000);
    } catch (error) {
      console.error('Error publishing article:', error);
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      {showConfetti && <Confetti />}
      <Box>
        <Typography variant="h4" component="h1" textAlign="center" color="primary" gutterBottom>
          Publish Your Article
        </Typography>

        <div className="container mx-auto px-4 lg:px-20 text-justify text-gray-700">
          <p>
            When submitting an article, please include the following: 
            <strong> Title</strong>, <strong> Article</strong>, <strong> Bible Reading Verse(s)</strong>, 
            <strong> Summary of the Article</strong>, <strong>Selected Image</strong> and a <strong>Short Prayer</strong> related to the message.
            <br />
            <strong>NB</strong>: Articles are displayed exactly as how you type them
          </p>
        </div>

        <form onSubmit={handleSubmit}>
          <TextField
            label="Article Title"
            name="title"
            value={article.title}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            variant="outlined"
          />

          <Box sx={{ border: '1px solid #ccc', borderRadius: '5px', p: 2, minHeight: '400px', maxHeight: '600px', overflowY: 'auto' }}>
            <div>
              <Button onClick={() => toggleInlineStyle('BOLD')}>Bold</Button>
              <Button onClick={() => toggleInlineStyle('ITALIC')}>Italic</Button>
              <Button onClick={() => toggleInlineStyle('UNDERLINE')}>Underline</Button>
              <Button onClick={() => toggleBlockType('unordered-list-item')}>Bullet List</Button>
              <Button onClick={() => toggleBlockType('ordered-list-item')}>Numbered List</Button>
            </div>
            <Editor
              editorState={editorState}
              handleKeyCommand={handleKeyCommand}
              onChange={setEditorState}
              placeholder="Write your article here..."
            />
          </Box>

          <TextField
            label="Bible Verse Reading"
            name="bibleVerse"
            value={article.bibleVerse}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            variant="outlined"
            placeholder="e.g., John 3:16"
          />

          <TextField
            label="Summary"
            name="summary"
            value={article.summary}
            onChange={handleChange}
            multiline
            rows={3}
            fullWidth
            required
            margin="normal"
            variant="outlined"
          />

          <TextField
            label="Prayer"
            name="prayer"
            value={article.prayer}
            onChange={handleChange}
            multiline
            rows={3}
            fullWidth
            required
            margin="normal"
            variant="outlined"
          />

          <Box mt={2}>
            <input
              accept="image/*"
              type="file"
              onChange={handleImageChange}
              style={{ display: 'none' }}
              id="image-upload"
              required
            />
            <label htmlFor="image-upload">
              <Button variant="contained" color="secondary" component="span">
                {upload ? 'Pick an Image' : 'Change Image'}
              </Button>
            </label>
          </Box>

          {imagePreview && (
            <Box mt={2} textAlign="center">
              <Avatar
                src={imagePreview}
                alt="Selected Image"
                sx={{ width: 200, height: 200, borderRadius: 2, boxShadow: 3, objectFit: 'cover' }}
              />
            </Box>
          )}

          <Box textAlign="center" mt={4}>
            <Button type="submit" variant="contained" color="primary" size="large" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Publish Article'}
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
}

export default AddArticlePage;
