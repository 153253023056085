import React,{useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { useArticles } from '../Context/ArticlesContext'; // Import context

function ArticlesDetailedPage() {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
  const { articleId } = useParams();
  const { articles } = useArticles();

  const article = articles.find((item) => item.id === articleId);

  if (!article) {
    return <div>Article Loading ...</div>;
  }

  const renderBlock = (block) => {
    switch (block.type) {
      case 'header-two':
        return <h2 key={block.key}>{block.text}</h2>;
      case 'unstyled':
        return <p key={block.key}>{block.text}</p>;
      default:
        return <p key={block.key}>{block.text}</p>;
    }
  };

  const userName = article.user?.name || 'Anonymous';

  const formattedDate = new Date(article.timestamp?.seconds * 1000).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <div className="container mx-auto py-8 px-4 lg:px-20">
      {/* Date centered at the top */}
      <p className="text-xs text-center text-gray-500 mb-2">{formattedDate}</p>

      {/* Title centered and bold */}
      <h1 className="text-3xl font-bold text-center mb-4">{article.title}</h1>
      
      {/* Centered Article Image */}
      {article.imageUrl && (
        <div className="flex justify-center mb-4">
          <img
            src={article.imageUrl}
            alt={article.title}
            className="w-full lg:w-2/4 lg:h-[50vh] h-[30vh] object-cover"
           
          />
        </div>
      )}

      {/* Author's name in deep black text */}
      <div className="text-lg text-black font-semibold mb-4">
            <p className="text-xs  text-gray-500 mb-2"><strong>Written by: </strong>{userName}</p>
            <p className="text-[17px]  text-gray-500 mb-2"><strong>Bible Verse(s): </strong>{article.bibleVerse}</p>

      </div>

      {/* Article Body */}
      <div className="mb-4">
        {article.body.blocks.map((block) => renderBlock(block))}
      </div>

      {/* Summary */}
      <div className="mb-4">
        <h2 className="text-2xl font-bold mb-2">Summary</h2>
        <p>{article.summary}</p>
      </div>

      {/* Prayer */}
      <div className="mb-4">
        <h2 className="text-2xl font-bold mb-2">Prayer</h2>
        <p>{article.prayer}</p>
      </div>

      {/* Email section with mailto link */}
      <div className="text-center mt-8">
      {article.user?.email && (
        <button
          onClick={() => window.location.href = `mailto:${article.user.email}`}
          className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600"
        >
          Send the writer a mail
        </button>
      )}
    </div>
    </div>
  );
}

export default ArticlesDetailedPage;
