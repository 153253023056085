import React, { useEffect } from 'react'
import '../Testimonies/Testimonies.css'
import Footer from '../Footer/Footer';
import Testimony from "../Images/Testimony.mp4"
import AddTestimonyButton from './AddTestimonyButton';
import TestimoniesList from './TestimonyCard';


function Testimonies() {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <div id='testimonies'>
        <div className="relative w-full">
        <video
          src={Testimony}
          autoPlay
          loop
          muted
          playsInline
          className="absolute inset-0 w-full object-cover h-[500px]"
        />

        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-[500px] text-center text-white px-4">
        <h1 className="text-4xl lg:text-6xl font-bold mb-3">Faith Testimonies</h1>
        <p className="text-xl">Hear the amazing works the Lord has done in the lives of His people. May these stories inspire you to share your own testimony and encourage others on their faith journey.</p>
      </div>
      
      </div>

      <div className="lg:px-[150px]">
     
      <div className=" my-6">
        <h1 className="text-3xl pl-4 font-bold">TESTIMONIES</h1>
        <div className="mt-2 w-[410px] h-1 bg-purple-900 mx-auto lg:w-full"></div>
        
      </div>
    
      <p className="p-2 text-justify">
        As Romans 10:17 reminds us, "Faith comes from hearing the message," and at Mizpah International Ministry, we cherish the power of sharing these messages. Our testimonies are a vital part of this process, creating connections and fostering faith within our community.
      </p>
    
      <p className="p-2 text-justify">
        In 2 Corinthians 1:3-4, we learn that God comforts us in our struggles so that we can extend that comfort to others. Our testimonies serve this purpose—they inspire, uplift, and fortify our faith, demonstrating the incredible work of God in our lives.
      </p>
    
      <p className="p-2 text-justify">
        Let us celebrate our collective stories of faith, just as Psalm 66:16 calls us, "Come and hear, all you who fear God; let me tell you what he has done for me." Join us in this vibrant community where we share and inspire through our testimonies. <strong>To add your own testimony and inspire others, simply click on the message icon located at the extreme left of the page.</strong> 
      </p>
    </div>
    
    
<TestimoniesList/>
            <AddTestimonyButton/>
            <Footer />
        </div>
    )
}

export default Testimonies
