import React from 'react'
import '../About/About.css'
import Card from 'react-bootstrap/Card';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const aboutInfo = [
    {
        title: 'Our Values',
        subTitle: 'Genuine Affection',
        message: "We hold that everyone deserves to connect with God (Romans 10:13). This connection is expressed through mutual love - a tangible, genuine love. This love defines us and provides a path for anyone to relate to the Father through the love they experience in our community.",
        subTitle2: 'Genuine Empathy',
        message2: "We hold that everyone should witness God in a tangible manner. Everyone requires assistance - emotionally, physically, mentally, relationally, and spiritually. Nobody is spared. As we evolve to resemble Christ more, we consciously extend the empathy Christ showed us, aiming to create a significant impact in others' lives.",
        subTitle3: 'Real Communit',
        message3: "We believe in community, in family, and the local church. We are better together and draw strength from one another. We want to strengthen our homes, our families and our community. We want to be an encouragement to our friends, neighbors, and family. "
    },
    {
        title: 'Our Faith',
        subTitle: 'The Holy Scripture',
        message: "Both Old and New Testaments are God's inspired, foolproof words and His authoritative declarations. They alone finalize all doctrinal truths. (2 Timothy 3:15-17; 1 Thessalonians 2:13; 2 Peter 2:11)",
        subTitle2: 'God',
        message2: "The sole, authentic God has eternally and self-existently revealed Himself as the mighty 'I Am', the Creator of the cosmos and mankind's Redeemer. He shows Himself as the Father, Son, and Holy Spirit. (Deuteronomy 6:4, Isaiah 43:10-11, Matthew 28:28, Luke 3:22). The Divine exists as a trinity: The Father, Son, and Holy Spirit. Although each Trinity member performs distinct roles, they each carry equal might and authority and always operate in total unity. (Deuteronomy 6:4, Isaiah 61:1, Matthew 28:19, Mark 1:9-11, Luke 1:35, John 5:21-23, Romans 8:9-11, 1 Corinthians 8:6, 2 Corinthians 13:14, Hebrews 1:8-10, James 2:19)",
        subTitle3: 'Jesus',
        message3: "Jesus, the Son of The Divine, the Trinity's second person. On earth, He was fully human and fully Divine. He's the only man to live a sin-free life. Born of a virgin, lived sinlessly, performed miracles, died on the cross, being our sins' atonement through His bloodshed. He resurrected on the third day, as per scriptures, ascended to the Father's right hand, to return in power and glory one day, (John 1:1, 14, 20:28, 1 Timothy 3:16, Isaiah 9:6)"
    },
]

const aboutInfo2 = [
    {
        title: 'Fostering Forgiveness',
        subTitle: 'Embracing Forgiveness',
        message: "Colossians 3:13 teaches us to 'Bear with each other, forgive grievances, as the Lord forgave you.' This emphasizes forgiveness. It's not just about releasing resentment, but embracing forgiveness daily. It's a mindset that fosters peace and positivity. By forgiving, we free ourselves from negativity and open our hearts. Through forgiveness, we live in peace with ourselves and others.",
        subTitle2: 'Healing Power',
        message2: "Ephesians 4:32 teaches us to 'Be kind, compassionate, forgiving each other, as in Christ God forgave you.' It's not just a command, but a guide for love and compassion. Forgiveness isn't just about mending relationships, it's about healing ourselves. By forgiving, we let go of resentment, experience healing, and grow as compassionate individuals. Through forgiveness, we experience God's transformative love.",
        subTitle3: 'Foster Forgiveness',
        message3: "In Matthew 18:21-22, Peter asks Jesus about forgiveness. Jesus says, 'Not seven times, but seventy-seven times.' This scripture emphasizes making forgiveness a daily habit, letting go of resentment, and embracing love and understanding. It shows us the power of forgiveness."
    },
    {
        title: 'Christian Beliefs',
        subTitle: 'Humanity',
        message: "Genesis 1:27, Psalm 8:3-6, Romans 3:23, and Isaiah 53:6a; 59:1-2 teach us that humans, being God's creation, were designed for dignity. However, Adam's sin caused a fallen state, leading to rebellion and disobedience, called 'sin', which separates us from God.",
        subTitle2: 'Eternity',
        message2: "ohn 3:16, Romans 6:23, and Revelation 20:15 explain that we were created to exist forever, either eternally separated from God by sin or eternally with God through forgiveness and salvation. Heaven and Hell are real places of eternal existence.",
        subTitle3: 'Baptism',
        message3: "Mark 1:9-11 and Matthew 2 teach that baptism is a symbolic act that best illustrates the death, burial, and resurrection of Jesus Christ. It's not necessary for salvation, but it's an act of obedience closely associated with salvation. Believer's baptism is emphasized, where a person must first believe in Jesus Christ. Infants or those who don't understand God's plan of salvation can't make such a commitment. The order is belief first, then baptism."
    },

]
function About() {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (
        <div id='about' className='p-3 row'>

            <div className='header'>
                <h1 className='text-center uppercase' > We trust in the authenticity <br /> of God's Word, the Bible.</h1>
                <p className='text-justify font-h2Fonts p-3'>People who join us at Mizpah International Ministry come from diverse experiences and backgrounds. Some have roots in Catholic, Presbyterian, Methodist churches, while others have no religious affiliation at all. Suddenly, they find themselves at a Baptist ministry. What does this imply? Many associate Mizpah International Ministry with common stereotypes, but we assure you, Mizpah is far from typical. We're unique. We believe ministry should be enjoyable and inspiring, without compromising the truth of God's Word, the Bible. We're a Christian, evangelical ministry, dedicated to sharing the good news of Jesus Christ. As Mizpah ministry, we have distinct beliefs. Below, you'll find a summary of the essential Christian beliefs and the unique Mizpah beliefs that set us apart.</p>
            </div>
            <div className="col-md-6 text-white">
                {
                    aboutInfo.map(({ title, subTitle, message, subTitle2, message2, subTitle3, message3 }) => {
                        return (
                            <>
                                <h1 className='font-titleFonts row pt-5'>{title}</h1>

                                <Card className='mb-5'>
                                    <Card.Body>
                                        <Card.Title><h3 className='font-pFonts'>{subTitle}</h3></Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted"><p className='text-justify font-h1Fonts pb-2'>{message}</p></Card.Subtitle>
                                    </Card.Body>
                                </Card>
                                <Card className='mb-5'>
                                    <Card.Body>
                                        <Card.Title><h3 className='font-pFonts'>{subTitle2}</h3></Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted"> <p className='text-justify font-h1Fonts pb-2'>{message2}</p></Card.Subtitle>
                                    </Card.Body>
                                </Card>
                                <Card className='mb-5'>
                                    <Card.Body>
                                        <Card.Title><h3 className='font-pFonts'>{subTitle3}</h3></Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted"><p className='text-justify font-h1Fonts pb-2'>{message3}</p></Card.Subtitle>
                                    </Card.Body>
                                </Card>
                            </>

                        )
                    })
                }
                <h4 className='text-center text-primary font-pFonts pt-2'>Should you find yourself in need of prayer, we encourage you to contact Mizpah International Ministry. We stand ready to assist</h4>

                <Link to='/request' >
                    <button className="w-[100%] mt-4 h-16  bg-[#800080] mb-3  text-gray-200 text-xl inline-flex items-center justify-center rounded-md shadow-shadowOne hover:bg-opacity-40 hover:-translate-y-1 transition-all hover:text-blue-600 cursor-pointer duration-300">
                        Send Prayer Request</button>
                </Link>
            </div>
            <div className="col-md-6 text-white">
                {
                    aboutInfo2.map(({ title, subTitle, message, subTitle2, message2, subTitle3, message3 }) => {
                        return (
                            <>
                                <h1 className='font-titleFonts row pl-5 pt-5'>{title}</h1>

                                <Card className='mb-5'>
                                    <Card.Body>
                                        <Card.Title><h3 className='font-pFonts'>{subTitle}</h3></Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted"><p className='text-justify font-h1Fonts pb-2'>{message}</p></Card.Subtitle>
                                    </Card.Body>
                                </Card>
                                <Card className='mb-5'>
                                    <Card.Body>
                                        <Card.Title><h3 className='font-pFonts'>{subTitle2}</h3></Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted"> <p className='text-justify font-h1Fonts pb-2'>{message2}</p></Card.Subtitle>
                                    </Card.Body>
                                </Card>
                                <Card className='mb-5'>
                                    <Card.Body>
                                        <Card.Title><h3 className='font-pFonts'>{subTitle3}</h3></Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted"><p className='text-justify font-h1Fonts pb-2'>{message3}</p></Card.Subtitle>
                                    </Card.Body>
                                </Card>
                            </>
                        )
                    })
                }
                <h4 className='text-center text-primary font-pFonts pt-2'>Want To Help Spread The Gospel Globally? Donate To Mispah International Mnistry</h4>

                <Link to='/give' >
                    <button className="w-[100%] mt-4 h-16  bg-[#800080] mb-3  text-gray-200 text-xl inline-flex items-center justify-center rounded-md shadow-shadowOne hover:bg-opacity-40 hover:-translate-y-1 transition-all hover:text-blue-600 cursor-pointer duration-300">
                        Send Your Donations</button>
                </Link>


            </div>
            <Footer />
        </div>
    )
}

export default About
