import React, { useState } from "react";
import { useTestimonies } from "../Context/TestimoniesContext";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  CircularProgress,
  Pagination, // Import Pagination component
} from "@mui/material";
import { Link } from "react-router-dom"; // Assuming you're using react-router for navigation

const TestimoniesList = () => {
  const { testimonies, loading, error } = useTestimonies();

  // States for pagination
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const testimoniesPerPage = 6; // Testimonies to display per page (change this as needed)

  // Calculate the index range for the testimonies to show on the current page
  const indexOfLastTestimony = currentPage * testimoniesPerPage;
  const indexOfFirstTestimony = indexOfLastTestimony - testimoniesPerPage;
  const currentTestimonies = testimonies.slice(indexOfFirstTestimony, indexOfLastTestimony);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  if (loading) return <CircularProgress />;
  const getFirstTwoNames = (fullName) => {
    const names = fullName.split(" ");
    return names.length >= 2 ? `${names[0]} ${names[1]}` : fullName;
  };

  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <div>
      <Grid container spacing={4}>
        {currentTestimonies.length === 0 ? (
          <Typography>No testimonies available</Typography>
        ) : (
          currentTestimonies.map((testimony) => (
            <Grid
              item
              xs={12} // 1 card on mobile (xs)
              sm={6}  // 2 cards on small screens like tablets (sm)
              md={6}  // 2 cards on medium screens (md)
              lg={3}  // 4 cards on large screens like desktops (lg)
              key={testimony.id}
            >
              <Card
                sx={{
                  width: '95%',
                  margin: '12px',
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  backgroundColor: "#f9f9f9",
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                  borderRadius: '8px',
                  padding: '10px',
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <p className="text-1xl text-gray-500 mb-2">
                    <strong>Testimony by: </strong>{getFirstTwoNames(testimony.user.name)}
                  </p>
                  <p className="text-xs text-gray-500 mb-2">
                    <strong>From: </strong>{testimony.location}
                  </p>

                  <Typography
                    variant="body1"
                    paragraph
                    align="justify"
                    sx={{ fontSize: '1rem', marginBottom: "20px" }}
                  >
                    {testimony.message.substring(0, 130)}...
                  </Typography>

                  <Link to={`/testimonies/${testimony.id}`} style={{ textDecoration: 'none' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{
                        padding: '12px',
                        borderRadius: '8px',
                        fontWeight: 'bold',
                        backgroundColor: '#007bff',
                        '&:hover': {
                          backgroundColor: '#0056b3',
                        }
                      }}
                    >
                      Read More
                    </Button>
                  </Link>
                </CardContent>
              </Card>
            </Grid>
          ))
        )}
      </Grid>

      {/* Pagination Component */}
      <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <Pagination
          count={Math.ceil(testimonies.length / testimoniesPerPage)} // Total number of pages
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </div>
    </div>
  );
};

export default TestimoniesList;
