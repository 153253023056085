import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCommentAlt } from 'react-icons/fa'; // Importing a speech bubble icon from react-icons
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../Firebaseconfig'; // Import your Firebase auth object

function AddTestimonyButton() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleAddTestimonyClick = () => {
    if (isAuthenticated) {
      navigate('/add-testimony'); // Navigate to Add Testimony page
    } else {
      alert('You have to Sign In before you can upload your testimonies.');
      navigate('/signin'); // Redirect to sign-in page if not authenticated
    }
  };

  return (
    <div className="fixed bottom-5 left-5 flex items-center space-x-2" style={{ zIndex: 1000 }}>
      <button
        className="bg-blue-600 text-white p-4 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
        aria-label="Add Testimony"
        onClick={handleAddTestimonyClick} // Update function call
      >
        <FaCommentAlt size={20} /> {/* Use the new testimony icon */}
      </button>
    </div>
  );
}

export default AddTestimonyButton;
