import React from 'react'
import '../Footer/Footer.css'
import { FaFacebookF,FaYoutube,FaEnvelope, FaMapMarkerAlt,FaPhone , FaInstagram,FaTiktok  } from "react-icons/fa";
import { Link } from 'react-router-dom';


function Footer() {
  return (
    <footer>
   <div className='google-map mb-5'>
      <iframe src="https://maps.google.com/maps?q=fairfield,ohio,usa&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" title='Mizpah International Ministry'></iframe>
      </div>
         <div class="row">
    <div class="col col-6">
      <h3>Contacts <div class="underline"><span></span></div></h3>
      <p><FaEnvelope />mizpahint.ministries77@gmail.com </p>
      <p><FaPhone />+1 (513) 328-2864</p>
      <p><FaMapMarkerAlt />Fairfield, Ohio, USA</p>
      <div class="help-line">
       
      </div>
    </div>
    <div class="col col-6">
      <h3>For You <div class="underline"><span></span></div></h3>
      <ul><li><Link to='/request'>Prayer Request</Link></li></ul>
      <ul><li><Link to='/faq'>FAQ</Link></li></ul>
      <p><FaEnvelope />mizpahint.ministries77@gmail.com </p>
      <div class="help-line">
       
      </div>
    </div>
    <div class="col col-6">
      <h3>Navigate <div class="underline"><span></span></div></h3>
      <ul><li><Link to='/about' >Beliefs</Link></li></ul>
      <ul><li><Link to='/give' >Give</Link></li></ul>
      <ul><li><Link to='/testimonies' >Testimonies</Link></li></ul>
      <ul><li><Link to='/articles' >Articles</Link></li></ul>
      <ul><li><Link to='/contact'>Messages</Link></li></ul>
    </div>
    <div class="col col-6 ">
      <h3>Follow Us<div class="underline"><span></span></div></h3>

      <div class="social-icons flex gap-4">
         <a className="w-12-h-12 bg-black bg-opacity-25 text-gray-200 text-xl inline-flex items-center justify-center rounded-md shadow-shadowOne hover:bg-opacity-40 hover:-translate-y-1 transition-all hover:text-red-500 cursor-pointer duration-300" href="https://www.facebook.com/mizpahministryworldoutreach?mibextid=b06tZ0"><FaFacebookF /></a>
         <a className="w-12-h-12 bg-black bg-opacity-25 text-gray-200 text-xl inline-flex items-center justify-center rounded-md shadow-shadowOne hover:bg-opacity-40 hover:-translate-y-1 transition-all hover:text-red-500 cursor-pointer duration-300" href="https://www.instagram.com/mizpah_family"><FaInstagram/></a>
         <a className="w-12-h-12 bg-black bg-opacity-25 text-gray-200 text-xl inline-flex items-center justify-center rounded-md shadow-shadowOne hover:bg-opacity-40 hover:-translate-y-1 transition-all hover:text-red-500 cursor-pointer duration-300" href="https://www.youtube.com/@mizpahinternationalministr783"><FaYoutube /></a>
         <a className="w-12-h-12 bg-black bg-opacity-25 text-gray-200 text-xl inline-flex items-center justify-center rounded-md shadow-shadowOne hover:bg-opacity-40 hover:-translate-y-1 transition-all hover:text-red-500 cursor-pointer duration-300" href="https://www.tiktok.com/@mizpah_family1?_t=8fdBkpD3mrN&_r=1"><FaTiktok/></a>
      </div>
    </div>
    <div id="mc_embed_signup" style={{width: '100%'}}>
            <form action="https://gmail.us8.list-manage.com/subscribe/post?u=8d5fd4efbd2f1aebf7d505e14&amp;id=3310781075&amp;f_id=00ba10e0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
                <div id="mc_embed_signup_scroll">
                    <h2>Subscribe To Our Newsletter</h2>
                    <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
                    <div className="mc-field-group">
                        <label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span></label>
                        <input type="email" name="EMAIL" className="text-dark required email" id="mce-EMAIL" required placeholder="johndoe@gmail.com" />
                    </div>
                    <div className="mc-field-group">
                        <label htmlFor="mce-FNAME"> Name </label>
                        <input type="text" name="FNAME" className="text-dark text" id="mce-FNAME" required placeholder="John Doe" />
                    </div>
                    <div className="mc-field-group">
                        <label htmlFor="mce-PHONE">Phone Number </label>
                        <input type="text" name="PHONE" className="text-dark REQ_CSS" id="mce-PHONE" required placeholder="+0000000000" />
                    </div>
                </div>
                <div id="mce-responses" className="clear foot">
                    <div className="response" id="mce-error-response" style={{display: 'none'}}></div>
                    <div className="response" id="mce-success-response" style={{display: 'none'}}></div>
                </div>
                <div className="clear">
    <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
</div>
            </form>
        </div>
  </div>
  <hr></hr>
  <p class="copyright">© 2023 MIZPAH INTERNATIONAL MINISTRY - All Rights Reserved.</p>
  <p class="pb-5 creation ">Site was created by    
  <a href="https://linktr.ee/agyemang166" className='font-pFonts text-[10px] text-decoration-none text-warning'>AgyemangDEV</a> 
  ,<br /> designed by   <a href="https://linktr.ee/abrewa_graphics" className='font-pFonts text-[10px] text-decoration-none text-warning'> {' '}Abrewa Graphics</a> 
  </p>
 
  
    </footer>
  )
}

export default Footer
